<template>
<div class="container-fluid">
  <div class="navbar">
    <Header />
  </div>   
  <div class="body_pad">
    <vue-calendly url="https://calendly.com/girardjames/60min" :height="700"></vue-calendly>
  </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Calendly",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
.body_pad{
  padding-top: 90px;
}
@media only screen and (max-width:900px){
  .body_pad {
    padding-top: 150px;
  }
}
</style>