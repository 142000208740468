<template>
<div class="container-fluid">
  <div class="navbar">
    <Header />
  </div>
    <div  class="body_pad ">
      <div id="title">
        <b-container>
          <b-row>
            <b-col class="col-md-2">
            </b-col>
            <b-col class="col-md-10 text-align-center">
              <h1 class="accompagnement">
                Mes Accompagnements
              </h1>
            </b-col>
            <b-col class="col-md-2">
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid class="bv-example-row">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4">
              <div class="col-md-12">
                <br>
                <div class="hovereffect">
                  <img class="img-responsive" src="../../src/assets/card-h03.jpg" alt="Hypnose">
                  <div class="overlay">
                    <b-link to="/Hypnose"><h2>Hypnose</h2></b-link>
                      <p>
                        <b-link to="/Hypnose">C'est parti!</b-link>
                      </p>
                  </div>
                </div>
              </div>  
            </div>
            <div class="col-md-4">
              <div class="col-md-12">
                <br>
                <div class="hovereffect">
                  <img class="img-responsive" src="../../src/assets/card-s05.jpg" alt="Sophrologie">
                  <div class="overlay">
                    <b-link to="/Sophrologie"><h2>Sophrologie</h2></b-link>
                      <p>
                        <b-link to="/Sophrologie">C'est parti!</b-link>
                      </p>
                  </div>
                </div>
              </div>  
            </div>
            <div class="col-md-4">
              <div class="col-md-12">
                <br>
                <div class="hovereffect">
                  <img class="img-responsive" src="../../src/assets/card-r09.jpg" alt="Réflexologie">
                  <div class="overlay">
                    <b-link to="/Reflexologie"><h2>Réflexologie</h2></b-link>
                      <p>
                        <b-link to="/Reflexologie">C'est parti!</b-link>
                      </p>
                  </div>
                </div>
              </div>  
            </div>
          </div>
          <br>
          <br>
          <br>
          <br>
          <br>
        </div>
        <Footer />
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Accompagnement",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
  .accompagnement{
    text-align: left;
  }
  .img-fit {
    max-width: 100%;
  }
  @media only screen and (max-width: 500px) {
    .accompagnement{
      font-size:18px !important;
      text-align: center;
    }
    .img-fit {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 900px) {
    .accompagnement{
      text-align: center;
      font-size:20px;
    }
    .img-fit {
      max-width: 100%;
    }
  }
  .hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
    background: #42b078;
  }

  .hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 50px 20px;
  }

  .hovereffect img {
    display: block;
    position: relative;
    max-width: none;
    width: calc(100% + 20px);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-10px,0,0);
    transform: translate3d(-10px,0,0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .hovereffect:hover img {
    opacity: 0.4;
    filter: alpha(opacity=40);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .hovereffect h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    overflow: hidden;
    padding: 0.5em 0;
    background-color: transparent;
  }

  .hovereffect h2:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    content: '';
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(-100%,0,0);
    transform: translate3d(-100%,0,0);
  }

  .hovereffect:hover h2:after {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  .hovereffect a, .hovereffect p {
    color: #FFF;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
  }

  .hovereffect:hover a, .hovereffect:hover p {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }



  mark {
    background-color: #786780;
    color: rgb(255, 255, 255);
  }
  .copyright{
    text-align: center;
    /* margin-top: 1rem; */
  }
  .link{
    float: left;
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  .link:hover {
    background: #786780;
    color: black;
  }
  .fixed-bottom2{
    height:10% !important;
  }
  .fa {
    padding: 10px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 0px 0px;
  }

  .fa:hover {
      opacity: 0.7;
  }

  .fa-facebook {
    color: white;
  }
  .fa-youtube {
    color: white;
  }

  .fa-instagram {
    color: white;
  }

  .fa-pinterest {
    color: white;
  }

</style>