<template>
  <div class="container-fluid">
  <div class="navbar">
    <Header />
  </div>
    <div class="body_pad">
      <div id="title">
        <b-container>
          <b-row>
            <b-col class="col-md-2">
            </b-col>
            <b-col class="col-md-10 text-align-center">
              <h1>
                Qui suis-je ?
              </h1>
            </b-col>
            <b-col class="col-md-2">
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-1">
            </div>
            <div class="col-md-7">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Bonjour, je suis Rabiaa, spécialiste en révélation de potentiel intérieur.</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span>
                        Depuis mon adolescence, je suis passionnée de développement personnel. J’ai toujours été curieuse de savoir comment notre corps fonctionnait, puis cette soif de connaissances a évolué vers un approfondissement de mes capacités sensitives et intuitives.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span>
                        De mes premières séances de relaxation et de respiration consciente au collège, j’ai continué à explorer mes émotions au travers de l’écriture et de la pratique de la méditation.                     
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span>
                        En parallèle, j’avais le souhait que ma pratique professionnelle soit alignée avec les valeurs de solidarités qui m’animent depuis toujours. Mon empathie et ma sensibilité m’ont amené à me former dans la relation d’aide et d’accompagnement (Éducation spécialisée). Durant plus de 15 ans, j’ai accompagné des enfants, adolescents, adultes et familles fragilisées, par une pathologie chronique, un handicap et lors d’une période de vie difficile.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span>
                        Toutes ces années ont été riches d’enseignements et d’expériences humaines très fortes. J’y ai réalisé qu’importe l’épreuve, il y a toujours un chemin pour la traverser. Et ce chemin se trouve à l’intérieur de chacun d’entre nous.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span>
                        C’est en parcourant mon chemin et  notamment au travers d’une maladie qui m’a personnellement touchée et fragilisée à plusieurs périodes de ma vie que j’ai fait le choix, en 2017, de faire évoluer ma voie professionnelle.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span>
                        Depuis, je suis formée aux Thérapies brèves (Hypnose ericksonienne, Sophrologie psychocomportementale RNCP, PNL et Ressource sensorielle®), aux pratiques de santé alternative (Réflexologie plantaire, Chi Nei Tsang et Massages) et aux pratiques énergétiques (Soin à l’enfant intérieur, Reiki et LaHoChi).
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span>
                        Chez vous à Thouaré sur Loire ou Carquefou (sans surplus tarifaire), de Nantes à Ancenis et à distance par appel vidéo, je vous accompagne à retrouver votre joie de vivre, croire à nouveau en vous et prendre la responsabilité de votre vie avec plus d’enthousiasme.
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous avec Rabiaa Girard»</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Parcours et Formation</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span>
                        Lorem 
                      </span>
                    </p>

                  </div>
                </div>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
            <div class="col-md-3">
              <div class="col-md-12 wrap">
                <div>
                  <img src="../../src/assets/Quisuisje-rabiaa-01.jpg" class="img-thumbnail" alt="Hypnose">
                  <br>
                  <br>
                  <h1>
                    Rabiaa Girard
                  </h1>
                  <span>
                    <strong>> Praticienne en Hypnose</strong>
                  </span>
                  <br>
                  <span>
                    <strong>> Praticienne en Sophrologie</strong>
                  </span>
                  <br>
                  <span>
                    <strong>> Praticienne en Réflexologie</strong>
                  </span>
                </div>
                <div id="container_12048240" class=" sb-bloc sb-bloc-level-3 bloc-element-link  " style="padding: 10px 10px 10px 10px;">
                  <div class="element element-link link-lg">
                    <a class=" sb-vi-btn forced-color skin_1 content-link" href="/prendre-rendez-vous-hypnotherapeute-lille">
                      <span>
                        <span class="inner-text">Prendre rendez-vous avec Rabiaa Girard</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-1">  
            </div>
            <br>
          </div>
          <br>
          <Footer />
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Hypnose",
  components: {
    Header,
    Footer
  }
};
</script>
