<template>
  <div class="container-fluid">
    <div class="navbar">
      <Header />
    </div>
    <div class="body_pad">
      <div id="title">
        <b-container>
          <b-row>
            <b-col class="col-md-2">
            </b-col>
            <b-col class="col-md-10 text-align-center">
              <h1>
                Réflexologie plantaire
              </h1>
            </b-col>
            <b-col class="col-md-2">
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-1">
            </div>
            <div class="col-md-7">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Réflexologie plantaire</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Le pied est l’organe qui termine sa croissance en premier, il signe alors l’approche de la fin de la puberté. Ce pied adulte va vous porter, répartir le poids de votre corps et vous permettre de garder l’équilibre malgré les aspérités du chemin de la vie.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        La Réflexologie plantaire est pratiquée depuis l’Antiquité, traversant des civilisations sur plusieurs millénaires de l’Égypte, la Chine, l’Inde en passant par les Incas et les Indiens d’Amérique.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Le pied possède 7200 terminaisons nerveuses qui sont regroupées par zones réflexes. Ces zones représentent les organes, glandes et parties du corps humain, en miniature.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Le Réflexologie plantaire va permettre de stimuler la capacité d’autoguérison de votre corps, en pratiquant des pressions et massages de ces zones réflexes au niveau des pieds.
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Réflexologie plantaire indienne</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        En médecine traditionnelle Indienne (Ayurveda), la Réflexologie plantaire au bol Kansu, est un massage des pieds constitué de mouvements tantôt énergique/rapide puis lent/profond, l’effet relaxant est immédiat
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Son origine date de plus de 5000 ans et proviendrait des traditions et rites familiaux des régions de l’ouest de l’Inde. Traditionnellement, les enfants massaient leurs grands-parents avec le bol Kansu, c’était alors un moment privilégié d’échange entre les générations autour des histoires de la mythologie indienne.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Le bol Kansu de la taille de la paume d’une main est constitué d’un alliage de 5 métaux : cuivre, bronze, étain, argent et zinc.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Le principal composant est le cuivre, bien connu pour être conducteur d’énergie, il permet aussi d’absorber l’excès de chaleur du corps (le Feu) ce qui peut soulager la constipation, restaurer le calme et améliorer le sommeil. En combinaison avec le ghee (beurre clarifié), le zinc agit au niveau du tissu musculaire et le bronze œuvre comme catalyseur entre le zinc et le cuivre.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        La Réflexologie indienne permet d’apporter de la détente, du bien-être et apaise tout en insufflant une énergie nouvelle.
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
            <div class="col-md-3">
              <div class="col-md-12 wrap">
                <div>
                  <img src="../../src/assets/reflexo-03.png"  alt="Reflexologie Plantaire" loading="lazy" srcset="../../src/assets/card-r07.jpg 300w" sizes="30px">
                  <br>
                  <br>
                  <span>
                    <strong><h4>Réflexologie Plantaire indienne :</h4></strong>
                  </span>
                  <span>
                    <u>Durée:</u>
                    <strong> 30 minutes</strong>
                  </span>
                  <br>
                  <span>
                    <u>Coût:</u>
                  </span>
                  <strong> 30,00 €*</strong>
                  <br><br>
                </div>
                  <span>
                    <p class="text-right" style="font-size:10px; position: absolute; bottom:0; right:5px">*Surplus de 10€ au delà de 10 km en périphérie de Carquefou</p>
                  </span>
              </div>
            </div>
            <div class="col-md-1">
          </div>
          <br>
        </div>
        <br>
        <Footer />
        </div>  
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Reflexologie",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
  .follow {
    font-size:2;
  }
  .navmenu { margin: 0 auto }
  .navlink { display: flex; justify-content: center }
  .img-fit {
    max-width: 100%;
    height: 56px
  }
  @media only screen and (max-width: 500px) {
    .img-fit {
      max-width: 100%;
      height: 40px;
      align-content: center;
    }
  }
  @media only screen and (max-width: 900px) {
    .img-fit {
      max-width: 100%;
      height: 49px
    }
  }
  mark {
    background-color: #786780;
    color: rgb(255, 255, 255);
  }
  .copyright{
    text-align: center;
    /* margin-top: 1rem; */
  }
  @media only screen and (max-width: 900px) {
    .copyright{
      text-align: center;
      font-size: 6px;
      position: relative;
      /* margin-top: 1rem; */
    }
  }
  .link{
    float: left;
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 15px 9px;
      text-decoration: none;
      font-size: 17px;
    }
  }
  @media only screen and (max-width: 500px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 4px 9px;
      text-decoration: none;
      font-size: 14px;
    }
  }
  .link:hover {
    background: #786780;
    color: black;
  }
  .fixed-bottom2{
    height:10% !important;
  }
  @media only screen and (max-width: 900px) {
    .fixed-bottom2{
      height:30% !important;
    }
    }
  .fa {
    padding: 7px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 0px 0px;
  }

  .fa:hover {
      opacity: 0.7;
  }

  .fa-facebook {
    color: white;
  }
  .fa-youtube {
    color: white;
  }

  .fa-instagram {
    color: white;
  }

  .fa-pinterest {
    color: white;
  }

</style>