<template>
  <div class="container-fluid">
  <div class="navbar">
    <Header />
  </div>
    <div class="body_pad">
      <div id="title">
        <b-container>
          <b-row>
            <b-col class="col-md-2">
            </b-col>
            <b-col class="col-md-10 text-align-center">
              <h1>
                Hypnose
              </h1>
            </b-col>
            <b-col class="col-md-2">
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-1">
            </div>
            <div class="col-md-7">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Hypnose à domicile</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        L’hypnose est un état naturel connu et expérimenté par chacun d’entre nous.
                        Par exemple, lorsqu’au son d’une musique préférée, votre mental se laisse entrainer par le rythme et que vous perdez la notion du temps. Aussi, lorsque vous regardez un film, vous êtes absorbés par le suspens et votre être tout entier est concentré sur ce moment, votre être est en état d’hypnose, votre inconscient est actif.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        L’état d’hypnose est ce moment où vous êtes bien présent tout en étant un peu ailleurs. Ce qui est mobilisé chez vous fait alors partie d’une réaction inconsciente.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        L’hypnose est un fabuleux outil pour accéder à vos ressources d’ordinaire indisponibles à l’état conscient. Cela va bien au-delà des représentations de l’imaginaire collectif (le fameux « Dormez je le veux ! ») et de la vision de phénomènes produits dans le monde du spectacle.
                      </span>
                    </p>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        L’hypnose ericksonienne tient son nom de son fondateur, Milton Erickson, psychiatre et neurologue américain. Il explique que, notre inconscient possède en lui toutes les solutions et les ressources dont nous avons besoin pour résoudre nos difficultés.
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Hypnose en visio</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Lorem 
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
            <div class="col-md-3">
              <div class="col-md-12 wrap">
                <div>
                  <img src="../../src/assets/bg-h04.jpg" class="img-thumbnail" alt="Hypnose">
                  <br>
                  <br>
                  <h1>
                    Tarifs
                  </h1>
                  <br>
                    <span>
                    </span>
                    <br>
                    <span>
                      <strong>Hypnose Adulte :</strong>
                      <strong> 75 €</strong><span style="padding:0 0 0 40px;"/>
                      <br>
                      <u>Durée</u> :
                      <strong>60 minutes</strong>
                    </span>
                    <br>
                    <br>
                    <span>
                    <strong>Hypnose Enfant et Adolescent :</strong>
                    <strong> 60 €</strong>
                    <br>
                    <u>Durée</u> :
                    <strong>60 minutes</strong>
                    </span>
                    <br>
                    <br>
                    <span>
                    <strong>Arrêt-tabac :</strong>
                    <strong> 85 €</strong>
                    <br>
                    <u>Durée</u> :
                    <strong>60 minutes</strong>
                    </span>
                </div>
              </div>
            </div>
            <div class="col-md-1">
          </div>
          <br>
        </div>
        <br>
        <Footer />
</div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Hypnose",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
  .follow {
    font-size:2;
  }
  .navmenu { margin: 0 auto }
  .navlink { display: flex; justify-content: center }
  .img-fit {
    max-width: 100%;
    height: 56px
  }
  @media only screen and (max-width: 500px) {
    .img-fit {
      max-width: 100%;
      height: 40px;
      align-content: center;
    }
  }
  @media only screen and (max-width: 900px) {
    .img-fit {
      max-width: 100%;
      height: 49px
    }
  }
  mark {
    background-color: #786780;
    color: rgb(255, 255, 255);
  }
  .copyright{
    text-align: center;
    /* margin-top: 1rem; */
  }
  @media only screen and (max-width: 900px) {
    .copyright{
      text-align: center;
      font-size: 6px;
      position: relative;
      /* margin-top: 1rem; */
    }
  }
  .link{
    float: left;
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 15px 9px;
      text-decoration: none;
      font-size: 17px;
    }
  }
  @media only screen and (max-width: 500px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 4px 9px;
      text-decoration: none;
      font-size: 14px;
    }
  }
  .link:hover {
    background: #786780;
    color: black;
  }
  .fixed-bottom2{
    height:10% !important;
  }
  @media only screen and (max-width: 900px) {
    .fixed-bottom2{
      height:30% !important;
    }
    }
  .fa {
    padding: 7px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 0px 0px;
  }

  .fa:hover {
      opacity: 0.7;
  }

  .fa-facebook {
    color: white;
  }
  .fa-youtube {
    color: white;
  }

  .fa-instagram {
    color: white;
  }

  .fa-pinterest {
    color: white;
  }

</style>