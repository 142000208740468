<template>
  <div class="container-fluid">
    <div class="navbar">
      <Header />
    </div>
    <div class="body_pad">
      <div id="title">
        <b-container>
          <b-row>
            <b-col class="col-md-2">
            </b-col>
            <b-col class="col-md-10 text-align-center">
              <h1>
                Sophrologie Ludique
              </h1>
            </b-col>
            <b-col class="col-md-2">
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-1">
            </div>
            <div class="col-md-7">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Sophrologie Ludique à domicile de 7 à 12 ans</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                      Pour apprendre avec les contes et par le jeu des techniques de respiration, de détente et de mieux-être grâce à des protocoles d’accompagnements adaptés à votre enfant.                   
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Sophrologie à domicile – Adolescents et Adultes</strong>
                    </h2>
                    <p style="text-align: justify;">
                      <span style="font-size: 12pt;">
                        Pour apprendre avec sa respiration et par des mouvements doux à se détendre et optimiser sa préparation face à un événement stressant grâce à un protocole d’accompagnement personnalisé. 
                      </span>
                    </p>
                    <p>
                      <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </p>
                  </div>
                </div>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
            <div class="col-md-3">
              <div class="col-md-12 wrap">
                <div>
                  <img src="../../src/assets/card-s06.jpg" class="rounded img-thumbnail" alt="Sophrologie">
                  <br>
                  <br>
                  <span>
                    <strong><h4>Sophrologie Ludique :</h4></strong>
                  </span>
                  <span>
                    <u>Durée:</u>
                    <strong> 60 minutes</strong>
                  </span>
                  <br>
                  <span>
                    <u>Coût:</u>
                  </span>
                  <strong> 35,00 €*</strong>
                  <br><br>
                  <span>
                    <strong><h4>Sophrologie à domicile :</h4></strong>
                  </span>
                  <span>
                    <u>Durée:</u>
                    <strong> 60 minutes</strong>
                  </span>
                  <br>
                  <span>
                    <u>Coût:</u>
                  </span>
                  <strong> 45,00 €*</strong>
                  <br><br>
                </div>
                  <span>
                    <p class="text-right" style="font-size:10px; position: absolute; bottom:0; right:5px">*Surplus de 10€ au delà de 10 km en périphérie de Carquefou</p>
                  </span>
              </div>
            </div>
            <div class="col-md-1">
          </div>
          <br>
        </div>
        <br>
        <Footer />
</div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Sophrologie",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
  .follow {
    font-size:2;
  }
  .navmenu { margin: 0 auto }
  .navlink { display: flex; justify-content: center }
  .img-fit {
    max-width: 100%;
    height: 56px
  }
  @media only screen and (max-width: 500px) {
    .img-fit {
      max-width: 100%;
      height: 40px;
      align-content: center;
    }
  }
  @media only screen and (max-width: 900px) {
    .img-fit {
      max-width: 100%;
      height: 49px
    }
  }
  mark {
    background-color: #786780;
    color: rgb(255, 255, 255);
  }
  .copyright{
    text-align: center;
    /* margin-top: 1rem; */
  }
  @media only screen and (max-width: 900px) {
    .copyright{
      text-align: center;
      font-size: 6px;
      position: relative;
      /* margin-top: 1rem; */
    }
  }
  .link{
    float: left;
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 15px 9px;
      text-decoration: none;
      font-size: 17px;
    }
  }
  @media only screen and (max-width: 500px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 4px 9px;
      text-decoration: none;
      font-size: 14px;
    }
  }
  .link:hover {
    background: #786780;
    color: black;
  }
  .fixed-bottom2{
    height:10% !important;
  }
  @media only screen and (max-width: 900px) {
    .fixed-bottom2{
      height:30% !important;
    }
    }
  .fa {
    padding: 7px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 0px 0px;
  }

  .fa:hover {
      opacity: 0.7;
  }

  .fa-facebook {
    color: white;
  }
  .fa-youtube {
    color: white;
  }

  .fa-instagram {
    color: white;
  }

  .fa-pinterest {
    color: white;
  }

</style>