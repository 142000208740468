<template>
  <div class="container-fluid">
    <div class="navbar">
      <Header />
    </div>
    <div class="body_pad">
      <div id="title">
        <b-container>
          <b-row>
            <b-col class="col-md-2">
            </b-col>
            <b-col class="col-md-10 text-align-center">
              <h1>
                Me contacter
              </h1>
            </b-col>
            <b-col class="col-md-2">
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-container fluid >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-1">
            </div>
            <div class="col-md-7">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 text wrap">
                    <h2>
                      <strong>Contacter Saisons de vie</strong>
                    </h2>
                      <div style="font-size:20px" class="row">
                        <div class="col-md-12">
                          <i class="fa fa-home fa-fw" style="color:#0CBAB4"></i>
                          <span>
                            <strong style="margin:0px 10px"> Rabiaa Girard </strong>
                          </span>
                        </div>
                        <div class="col-md-12">
                          <i class="fa fa-map-marker fa-fw" style="color:#0CBAB4"></i>
                          <span>
                            <strong style="margin:0px 10px"> 
                              1 rue de Lanngeais - 44470 Thouaré-sur-Loire
                            </strong>
                          </span>

                        </div>
                        <div class="col-md-12">
                          <i class="fa fa-phone fa-fw" style="color:#0CBAB4"></i>
                          <span class="element-contact-text">
                            <strong style="margin:0px 10px"> <a href="tel:+33695446202">06 95 44 62 025</a> </strong>
                          </span>
                        </div>
                      </div>
                      <br>
                      <div class="col-md-12 text-center">
                        <link href='http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900' rel='stylesheet' type='text/css'>
                        <link href='http://fonts.googleapis.com/css?family=Inconsolata:400,700' rel='stylesheet' type='text/css'>
                        <link href='http://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800' rel='stylesheet' type='text/css'>
                        <link href='http://fonts.googleapis.com/css?family=Signika+Negative:400,700' rel='stylesheet' type='text/css'>
                        <link href="http://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css" rel="stylesheet">
                        <a target="_blank" href="https://www.facebook.com/saisonsdevie/" class="button facebook"><span><i class="fa fa-facebook"></i></span><p>Facebook</p></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCb_0KnylH4QDwPra6VV1WJg/videos" class="button youtube"><span><i class="fa fa-youtube"></i></span><p>YouTube</p></a>
                        <a target="_blank" href="https://www.instagram.com/saisonsdevie/?hl=fr" class="button instagram"><span><i class="fa fa-instagram"></i></span><p>instagram</p></a>
                        <a target="_blank" href="https://www.pinterest.fr/rabiaagirard/" class="button pinterest"><span><i class="fa fa-pinterest"></i></span><p>pinterest</p></a>
                      </div>
                        <b-link  class="btn text-primary" to="/Calendly">Prendre rendez-vous »</b-link >
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-12 text wrap">
                      <h2>
                        <strong>Localisation: </strong><br>
                      </h2>
                      <div class="row">
                      <div class="col-md-8">
                        <b-link target="_blank" href="https://goo.gl/maps/q8S1YTLEP2Sd1At79">
                          <img src="../../src/assets/zone_contact_02.png" class="img-fit" text-center style="width:100%; height:100%" alt="couverture">
                        </b-link>
                        Aglomération Nantaise
                      </div><br><br>
                      <div class="col-md-4">
                        <fieldset class="border p-2">
                          <legend  class="w-auto">Zones</legend>
                          <p style="background-color: #90405d;">
                            Déplacement à domicile sans surcoût.
                          </p>
                          <p style="background-color: #9597c1;">
                            Déplacement à domicile avec surcoût de 10€ à prévoir
                          </p>
                          <p style="color: #000000; background-color: #fbf7e7;">
                            Prestation visio à travers le monde sans surcoût.
                          </p>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <br>
                <br>
                <br>
              </div>
            </div>
            <div class="col-md-3">
              <div class="col-md-12 wrap">
                <div>
<div class="row">
    <div class="col-md-12 col-md-offset-3" id="form_container">
        <h2>Me contacter</h2>
        <form role="form" method="post" id="reused_form">
            <div class="row">
                <div class="col-sm-12 form-group">
                    <label for="message">
                      Votre Message:</label>
                    <textarea class="form-control" type="textarea" name="message" id="message" maxlength="6000" rows="7"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 form-group">
                    <label for="name">
                        Votre Prénom:</label>
                    <input type="text" class="form-control" id="name" name="name" required>
                </div>
                <div class="col-sm-12 form-group">
                    <label for="email">
                        Votre Email:</label>
                    <input type="email" class="form-control" id="email" name="email" required>
                </div>
            </div>


            <div class="row">
                <div class="col-sm-12 form-group">
                    <button type="submit" class="btn btn-lg btn-default pull-right" >Envoyer →</button>
                </div>
            </div>

        </form>
        <div id="success_message" style="width:100%; height:100%; display:none; ">
            <h3>Message envoyé avec succès!</h3>
        </div>
        <div id="error_message"
                style="width:100%; height:100%; display:none; ">
                    <h3>Erreur</h3>
                    Désolé, impossible d'envoyer le formulaire.
        </div>
    </div>
</div>
                </div>
              </div>
            </div>
            <div class="col-md-1">
          </div>
          <br>
        </div>
        <br>
        <Footer />
        </div>  
      </b-container>
    </div>
  </div>
</template>


<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";

  export default {
    name: "Reflexologie",
    components: {
      Header,
      Footer
    }
  };
</script>

<style>
  p{
    font-size: 15px;
    font-weight: 700;
  }
  .copyright a:hover { text-decoration: none; background: #fff; }

  .button {
    margin: 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding-right: 30px;
    padding-left: 70px;
    position: relative;
    background-color:rgb(0,0,0);
    color:rgb(255,255,255);
    text-decoration: none;
    text-transform: lowercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-shadow:0px 1px 0px rgba(0,0,0,0.5);
    -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=1,Color=#ff123852,Positive=true)";zoom:1;
    filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=1,Color=#ff123852,Positive=true);
    -moz-box-shadow:0px 2px 2px rgba(0,0,0,0.2);
    -webkit-box-shadow:0px 2px 2px rgba(0,0,0,0.2);
    box-shadow:0px 2px 2px rgba(0,0,0,0.2);
    -ms-filter:"progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=2,Color=#33000000,Positive=true)";
    filter:progid:DXImageTransform.Microsoft.dropshadow(OffX=0,OffY=2,Color=#33000000,Positive=true);
  }

  .button:hover{
    text-decoration: none;
    color: #eeeaee;
  }

  .button p{font-size: 18px;}
  .button span {
    position: absolute;
    left: 0;
    width: 50px;
    font-size:30px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid  rgba(0,0,0,0.15);
    text-decoration: none;
  }

  .button.facebook {
  background: #3b5998;
  }

  .button.youtube {
  background: #c4302b;
  }

  .button.instagram{
    background: #3f729b;
  }

  .button.pinterest{
    background: #c8232c;
  }

  .fa {
    padding: 7px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 0px 0px;
  }

  .fa:hover {
      opacity: 0.7;
  }

  .fa-facebook {
    color: white;
  }
  .fa-youtube {
    color: white;
  }

  .fa-instagram {
    color: white;
  }

  .fa-pinterest {
    color: white;
  }
</style>