<template>
    <div class="container-fluid">
        <div class="row">
        <div class="col-md-1">
        </div>
        <div class="col-md-10 wrap">
            <div class="text-center row">
                <div class="col-md-1">
                <b-link to="/">
                    <img src="../../src/assets/sdv-logo-white-150.png" class="img-fit" alt="Sophrologie Ludique">
                </b-link>
                </div>
                <div class="col-md-9">
                <ul class="nav navlink">
                    <li class="nav-item">
                    <b-link class="link active" to="/Quisuisje">Qui Suis-je?</b-link>
                    </li>
                    <li class="nav-item">
                    <b-link class="link active" to="/Accompagnement">Accompagnements</b-link>
                    </li>
                    <li class="nav-item">
                    <b-link class="link active" to="/Temoignages">Témoignages</b-link>
                    </li>
                    <li class="nav-item">
                    <b-link class="link active" to="/Contact">Contact</b-link>
                    </li>
                    <li class="nav-item">
                    <b-link class="link active" to="/Blog">Blog</b-link>
                    </li>
                    <li class="nav-item">
                    <b-link class="link active" to="/Conntact">Se connecter</b-link>
                    </li>
                </ul>
                </div>
                <div class="col-md-2">
                <p class="text-center">Suivez-moi:</p>
                <a target="_blank" href="https://www.facebook.com/saisonsdevie/" class="fa fa-facebook"></a>
                <a target="_blank" href="https://www.youtube.com/channel/UCb_0KnylH4QDwPra6VV1WJg/videos" class="fa fa-youtube"></a>
                <a target="_blank" href="https://www.instagram.com/saisonsdevie/?hl=fr" class="fa fa-instagram"></a>
                <a target="_blank" href="https://www.pinterest.fr/rabiaagirard/" class="fa fa-pinterest"></a>
                <link class="link" rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
                </div>
            </div>
            <div class="col-md-1">
            </div>
        </div>
    </div>
        <div class="row">
            <div class="col-md-12 copyright">
                © Saisons de vie 2018 - 2021 Tous droits réservés - Mentions légales
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style>
  .follow {
    font-size:2;
  }
  .navmenu { margin: 0 auto }
  .navlink { display: flex; justify-content: center }
  .img-fit {
    max-width: 100%;
    height: 56px
  }
  @media only screen and (max-width: 500px) {
    .img-fit {
      max-width: 100%;
      height: 40px;
      align-content: center;
    }
  }
  @media only screen and (max-width: 900px) {
    .img-fit {
      max-width: 100%;
      height: 49px
    }
  }
  mark {
    background-color: #786780;
    color: rgb(255, 255, 255);
  }
  .copyright{
    text-align: center;
    /* margin-top: 1rem; */
  }
  @media only screen and (max-width: 900px) {
    .copyright{
      text-align: center;
      font-size: 6px;
      position: relative;
      /* margin-top: 1rem; */
    }
  }
  .link{
    float: left;
    display: block;
    color: #ffffff;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 15px 9px;
      text-decoration: none;
      font-size: 17px;
    }
  }
  @media only screen and (max-width: 500px) {
    .link{
      float: left;
      display: block;
      color: #ffffff;
      text-align: center;
      padding: 4px 9px;
      text-decoration: none;
      font-size: 14px;
    }
  }
  .link:hover {
    background: #786780;
    color: black;
  }
  .fixed-bottom2{
    height:10% !important;
  }
  @media only screen and (max-width: 900px) {
    .fixed-bottom2{
      height:30% !important;
    }
    }
  .fa {
    padding: 7px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    margin: 0px 0px;
  }

  .fa:hover {
      opacity: 0.7;
  }

  .fa-facebook {
    color: white;
  }
  .fa-youtube {
    color: white;
  }

  .fa-instagram {
    color: white;
  }

  .fa-pinterest {
    color: white;
  }
</style>